<template>
  <scrollable-container :class="$style.scrollableContainer">
    <placeholder v-show="state === reportEnum.requestAwaiting" :text="text" @buttonClick="buildReport()" />
    <loader v-show="state === reportEnum.tableLoading" />
    <container v-show="state === reportEnum.tableReady" id="printable" :class="[
      { [$style.message]: state === reportEnum.requestAwaiting },
      $style.scrollableContent,
    ]"
    >
      <internet-table :columns="columns" :report-tree="reportTree" report-data="mail" />
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import { getMailStat } from '@/api/methods/reports/internet/index';
import Loader from '@/components/common/blocks/loader.vue';
import InternetTable from './internet-table.vue';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    InternetTable,
    Loader,
  },
  data: () => ({
    name: 'MailStat',
    reportTree: [],
    tableArray: [],
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    columns: [
      { name: 'Пользователь', field: 'name', sort: 2 },
      { name: 'Входящих', field: 'incoming', sort: 0 },
      { name: 'Исходящих', field: 'outcoming', sort: 0 },
      { name: 'Подозрительных', field: 'suspicious', sort: 0 },
    ],
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];
      let treeCopy = JSON.parse(JSON.stringify(tree));

      treeCopy = this.cutUncheckedNodes(treeCopy);

      return treeCopy;
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  methods: {
    cutUncheckedNodes(tree) {
      const childrenList = [];

      if (tree) {
        tree.forEach((node) => {
          if (node.checked) childrenList.push(node);
          else if (node.type === 'folder' && node.children) {
            node.children = this.cutUncheckedNodes(node.children);

            if (node.children.length > 0) childrenList.push(node);
          }
        });
      }

      return childrenList;
    },
    async buildReport() {
      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersTree
      ) {
        this.state = this.reportEnum.tableLoading;
        await this.fetch();
      }
    },
    async fetch() {
      this.reportTree = [];

      this.promise = await getMailStat(
        this.computersTree,
        this.dateRange.dateFrom,
        this.dateRange.dateTo,
      ).then(({ data }) => {
        if (data) {
          this.reportTree = data.result.computersTree;

          this.state = this.reportEnum.tableReady;
        } else this.state = this.reportEnum.requestAwaiting;
      });
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
