import { getReportData } from '@/api/methods/reports/reports';

export function getMailStat(tree, dateFrom, dateTo) {
  const link = `/reports/internet/mail?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const object = { computersTree: tree };

  return getReportData(link, object);
}

export function getMessengersStat(tree, dateFrom, dateTo) {
  const link = `/reports/internet/messengers?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const object = { computersTree: tree };

  return getReportData(link, object);
}

export function getGroupedMessengerStat(tree, dateFrom, dateTo) {
  const link = `/reports/internet/details-messengers?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const object = { computersTree: tree };

  return getReportData(link, object);
}
